import React from "react";
import ReactDOM from "react-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { photos } from "./photos";


const rootElement = document.getElementById("root");
ReactDOM.render(
  <ImageGallery
    items={photos}
    showBullets={false}
    showFullscreenButton={true}
    showPlayButton={true}
    showThumbnails={true}
    slideDuration={0}
    slideInterval={2000}
    showNav={true}
    lazyLoad={true}
  />,
  rootElement
);
